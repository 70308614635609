import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { Article } from '@src/utils/hooks/api/useArticles';

import { Membership } from './ChangeMembershipFlow.types';

export function isDowngradePath(from: string, to: Membership) {
    switch (from) {
        case Membership.PREMIUM:
            return [Membership.COMFORT, Membership.BASIC].includes(to);
        case Membership.ULTIMATE:
            return [Membership.PREMIUM, Membership.COMFORT, Membership.BASIC].includes(to);
        default:
            return false;
    }
}

export function isFreezeExtra(item: ArticleByPaymentSchedule | Article) {
    // get article short description from correct source
    const articleDescription =
        'articleDescription' in item
            ? item.articleShortDescription.toLocaleLowerCase()
            : item.artc_short_description.toLocaleLowerCase();

    return articleDescription.includes('freeze');
}
