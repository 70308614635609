import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import {
    Membership,
    sessionCacheKey
} from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import {
    getFromSessionCache,
    setToSessionCache
} from '@src/services/utilities/sessionCache.service';
import DOMPurify from 'isomorphic-dompurify';
import { DateTime } from 'luxon';

const modalCopy = {
    [Membership.BASIC]: 'membership.change.overview.hasChanged.basic',
    [Membership.COMFORT]: 'membership.change.overview.hasChanged.basic',
    [Membership.PREMIUM]: 'membership.change.overview.hasChanged.premium',
    [Membership.ALLIN]: 'membership.change.overview.hasChanged.all_in',
    [Membership.ULTIMATE]: 'membership.change.overview.hasChanged.ultimate'
};

const getModalCopy = (membership: Membership) => modalCopy[membership];

export const ChangeMembershipModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [membershipType, setMembershipType] = useState(Membership.COMFORT);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== '/membership/changed') return;

        const searchParams = new URLSearchParams(location.search);
        const membershipType = searchParams.get('membershipType');

        if (!membershipType) return;

        const sessionData = JSON.parse(getFromSessionCache(sessionCacheKey) || '{}');

        if (
            !sessionData.modalShown ||
            DateTime.fromISO(sessionData.modalShown).diffNow('seconds').seconds < -10
        ) {
            setToSessionCache(sessionCacheKey, { membershipIsChanged: true, membershipType });
        }

        navigate({ search: '' });
    }, [location, navigate]);

    /**
     * When membership has changed, revalidate member data and show modal
     */
    useEffect(() => {
        if (location.pathname === '/membership/changed') {
            const { membershipIsChanged = false, membershipType } = JSON.parse(
                getFromSessionCache(sessionCacheKey) || '{}'
            );
            if (membershipIsChanged) {
                setMembershipType(membershipType);
                setIsOpen(membershipIsChanged);
            }
        }
    }, [location.pathname]);

    const onConfirm = () => {
        setIsOpen(false);
        setToSessionCache(sessionCacheKey, { modalShown: new Date().toISOString() });
    };

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} onModalClose={onConfirm}>
                    <StrokeText
                        stroke="last"
                        color="anthracite"
                        className="text-anthracite mb-4"
                        text={t('membership.change.overview.hasChanged')}
                    />
                    <Text size={2} className="mb-m">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(t(getModalCopy(membershipType)))
                            }}
                        />
                    </Text>
                    <Button
                        className="w-[50%] mx-auto min-w-[200px]"
                        text={t('membership.change.overview.modalButton')}
                        onClick={onConfirm}
                    />
                </Modal>
            ) : null}
        </>
    );
};
