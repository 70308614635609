import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';

import { CountryName } from './countryHelpers';

// GXR is group lessons (video platform), in France we don't have group lessons so the translation is different on purpose

const DEFAULT_BASIC_USP_KEYS = ['usp.basic.homeclub', 'usp.gxr.lessons'];
const DEFAULT_COMFORT_USP_KEYS = [
    'usp.comfort.clubs',
    'usp.comfort.virtual.gxr',
    'usp.comfort.gxr',
    'usp.comfort.app'
];
const DEFAULT_PREMIUM_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount'
];

const NEW_PREMIUM_USP_KEYS = [
    'usp.unlimited.access.new',
    'usp.app.workouts',
    'usp.group.classes',
    'usp.free.friend.once',
    'usp.massage.chair'
];

const DEFAULT_ALLIN_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount',
    'usp.nxt.voucher',
    'usp.smart.bike',
    'usp.app.bike'
];

const DEFAULT_ULTIMATE_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.free',
    'usp.freeze.free'
];

const BE_LU_PREMIUM_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount'
];

const BE_LU_ALLIN_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount',
    'usp.smart.bike',
    'usp.app.bike'
];

type USPCountryMapType = Readonly<Record<CountryName, Record<Membership, string[]>>>;

const USP_KEYS_BY_COUNTRY: USPCountryMapType = Object.freeze({
    Belgie: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: BE_LU_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    },
    France: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    },
    Germany: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        // Comfort is not available in Germany
        [Membership.COMFORT]: [],
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    },
    Luxembourg: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: BE_LU_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    },
    Nederland: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    },
    Spain: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: NEW_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS,
        [Membership.ULTIMATE]: DEFAULT_ULTIMATE_USP_KEYS
    }
});

export const getMembershipUspByCountry = (
    membershipType: Membership,
    countryName: CountryName,
    showNewPremium = false
) => {
    if (!showNewPremium && membershipType === Membership.PREMIUM) {
        if (countryName === CountryName.Belgie || countryName === CountryName.Luxembourg) {
            return BE_LU_PREMIUM_USP_KEYS;
        }

        return DEFAULT_PREMIUM_USP_KEYS;
    }

    return USP_KEYS_BY_COUNTRY[countryName ?? CountryName.Nederland]?.[membershipType] || [];
};
