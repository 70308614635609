import { useEffect, useState } from 'react';
import { ContractPeriod, Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { MembershipChange, PaymentSchedule } from '@src/utils/hooks/api/types/membershipChange';
import { useFetch } from '@src/utils/hooks/api/useFetch';

export type ChangeMembershipProps = PaymentSchedule & {
    membershipType: Membership;
    renew?: boolean;
    possibleIntervals: PaymentInterval[];
};

type UseChangeMembershipProps = {
    id: string;
    membership: Membership;
    interval: PaymentInterval;
    contractPeriod: ContractPeriod;
    renew?: boolean;
};

export const useChangeMembership = ({
    id,
    membership,
    interval,
    contractPeriod,
    renew = false
}: UseChangeMembershipProps) => {
    const { data, isError } = useFetch<{ membership: MembershipChange[] }>(
        !renew ? `/memberships/get-people-membership-change?peopleMembershipId=${id}` : null
    );

    const [membershipOptions, setMembershipOptions] = useState<ChangeMembershipProps[]>();

    useEffect(() => {
        if (data) {
            const available = data.membership
                .filter((item) => {
                    return (
                        filterMemberships(item) &&
                        isSameContract({ item, membership, contractPeriod }) &&
                        hasSameInterval(item, interval)
                    );
                })
                .map((item) => mapPaymentSchedule(item, interval))
                .sort(sortMembershipOptions);

            setMembershipOptions(available);
        }
    }, [data, interval, membership, contractPeriod]);

    return { membershipOptions, isLoading: !isError && !data, isError };
};

/**
 * hotfix: https://basic-fit.atlassian.net/browse/MM-3333
 * feature: https://basic-fit.atlassian.net/browse/MM-3711
 */
function filterMemberships(item: MembershipChange) {
    return item.online && !item.name.toLowerCase().includes('fit@work');
}

type IsSameContractProps = {
    item: MembershipChange;
    membership: Membership;
    contractPeriod: ContractPeriod;
};

function isSameContract({ item, membership, contractPeriod }: IsSameContractProps) {
    return item.membershipType !== membership || contractPeriod === ContractPeriod.FLEX;
}

function hasSameInterval(item: MembershipChange, interval: PaymentInterval) {
    return item.paymentschedule.map((schedule) => schedule.paymentInterval).includes(interval);
}

function mapPaymentSchedule(
    item: MembershipChange,
    interval: PaymentInterval
): ChangeMembershipProps {
    const isFlex = (schedule: PaymentSchedule) =>
        schedule.minimalContractPeriod === PaymentInterval.FourWeeks &&
        schedule.paymentInterval === PaymentInterval.FourWeeks;

    const schedule = item.paymentschedule.find(
        (schedule) =>
            (schedule.paymentInterval === interval && schedule.online && !isFlex(schedule)) ||
            item.membershipType === Membership.ALLIN
    );

    const possibleIntervals = item.paymentschedule.map((schedule) => schedule.paymentInterval);
    return {
        membershipType: item.membershipType,
        possibleIntervals,
        ...schedule
    } as ChangeMembershipProps;
}

const membershipSortOrder = {
    [Membership.BASIC]: 0,
    [Membership.COMFORT]: 1,
    [Membership.PREMIUM]: 2,
    [Membership.ALLIN]: 3,
    [Membership.ULTIMATE]: 4
};

function sortMembershipOptions(a: ChangeMembershipProps, b: ChangeMembershipProps) {
    return membershipSortOrder[a.membershipType] - membershipSortOrder[b.membershipType];
}
