import { getTranslatedPaymentInterval } from '@src/utils/helpers/memberHelpers';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { Article, Articles } from '@src/utils/hooks/api/useArticles';
import i18next from 'i18next';

import { availableAddons } from '../constants/addons';
import type { ChangeMembershipProps } from '../hooks/api/useChangeMembership';

export const isOneTimeArticle = (item: Partial<Article & ArticleByPaymentSchedule>) =>
    item.paymentscheduleArticlePeriodical === false ||
    item.peopleMembershipArticlePeriodical === false ||
    item.artc_periodical === false;

export const isActive = (item: Partial<Article & ArticleByPaymentSchedule>) => {
    return !!item?.peopleMembershipArticleId; // only available on bought articles
};

export const hasAlreadyBought = (
    articles: Article[],
    item: Partial<Article & ArticleByPaymentSchedule>
): boolean => {
    const bought = articles.find((article: Article) => article.articleId === item.articleId);
    return (
        !!bought &&
        (!item.paymentscheduleArticlePeriodical || !item.peopleMembershipArticlePeriodical)
    );
};

export const hasEndDate = (item: Article) => item.peopleMembershipArticleEnddate;

export const getArticleByName = (articles: Articles, description: string) => {
    if (articles) {
        return [...articles.availableArticles, ...articles.boughtArticles].find((article) =>
            article.articleShortDescription.includes(description)
        );
    }
};

/**
 * Apply discount to Personal Online Coach when feature is active
 */
export const handlePocDiscount = <T>(
    feature: { originalPrice: number; percentage: number } | undefined,
    item: T,
    price: number
) => {
    if (feature && feature.originalPrice > price) {
        return {
            ...item,
            ...{
                originalPrice: feature?.originalPrice,
                discountText: `${feature.percentage}% ${i18next.t('membership.extras.discount')}`
            }
        } as T;
    }
    return item as T;
};

/**
 * Helper for memberships/get-articles
 * The property articlePicture doesn't return a usable image
 * This mapper provides 2 properties based on articleId:
 * - image to use with the StockImage component
 * - backgroundColor to use in AddOn component
 */

const articleImages = [
    {
        title: 'Personal Online Coach',
        keywords: ['Coach'],
        image: 'add-ons-poc',
        backgroundColor: 'bg-grey'
    },
    {
        title: 'Personal Training Intro',
        keywords: ['PTI', 'Training Intro'],
        image: 'personal_01',
        backgroundColor: 'bg-grey'
    },
    {
        title: 'LiveGX',
        keywords: ['LiveGX', 'Live groepslessen'],
        image: 'training_03',
        backgroundColor: 'bg-orange'
    },
    {
        title: 'Yanga',
        keywords: ['Yanga'],
        image: 'yanga-01',
        backgroundColor: 'bg-mint'
    },
    {
        title: 'Freeze',
        keywords: ['Freeze'],
        image: 'freeze',
        backgroundColor: 'bg-grey'
    },
    {
        title: 'Massage Chair',
        keywords: ['Massage'],
        image: 'massage-chair',
        backgroundColor: 'bg-grey'
    }
];

export const getArticleImage = (id: string): { id: string; backgroundColor: string } => {
    const result = articleImages.find((item) =>
        item.keywords.find((keyword) => id.toLowerCase().includes(keyword.toLowerCase()))
    );
    if (result) {
        return { id: result.image, backgroundColor: result.backgroundColor };
    }
    return { id: 'friends', backgroundColor: 'bg-orange' };
};

/**
 *
 * @param article Takes an article from the get-articles endpoint
 * @param membership Can be either
 * The current membership: To display on the membership page
 * the selected membership: To display on the change membership page
 * @returns Translation key for the article period
 * @note wrap the result in a t() function
 */
export const getArticleIntervalText = (
    article: ArticleByPaymentSchedule | Article,
    membership: ChangeMembershipProps | null | any /* type of member is any */
) => {
    if (isOneTimeArticle(article)) return '78'; // one time
    const interval = membership?.paymentInterval || membership?.payment_plan.interval;
    return getTranslatedPaymentInterval(interval);
};

/**
 * Return filtered array of free articles and chosen extra's
 * @param extras - articles ordered by member
 * @param articles - all articles for chosen/current membership
 */
export const filterArticlesByPaymentSchedule = (
    extras: ArticleByPaymentSchedule[],
    articles: ArticleByPaymentSchedule[]
) => {
    const filtered = articles.filter(
        (article) => extras.find((extra) => extra.artc_id === article.artc_id) || article.artc_free
    );
    return filtered;
};

/**
 * We don't want to show the Pro-App and Massagechair because they are part of Premium
 * Todo: these items should be configurable or removed in the back-end
 */
export const getBuyableArticles = (articles: Article[]) =>
    articles
        .filter((item) => !item.peopleMembershipArticleFree && !item.paymentscheduleArticleFree)
        .filter((item) => !item?.hideKiosk)
        .filter((item) => !item?.peopleMembershipArticleHideKiosk)
        .filter((item) => !item.articleDescription.includes(availableAddons.PRO_APP))
        .filter((item) => !item.articleDescription.includes(availableAddons.MESSAGE_CHAIR))
        .filter((item) => !item.articleDescription.includes(availableAddons.DELIVERY))
        .filter((item) => !item.articleDescription.includes(availableAddons.PICK_UP))
        .filter((item) => !item.articleDescription.includes(availableAddons.BIKE))
        .filter((item) => !item.articleDescription.includes(availableAddons.ALL_IN_APP))
        .filter((item) => !item.articleShortDescription.includes(availableAddons.PRO_COACH))
        .filter((item) => !item.articleShortDescription.includes(availableAddons.LIVE_GX))
        .sort((a, b) => a.articleShortDescription.localeCompare(b.articleDescription));
