import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { NavMenu } from '@src/components/NavMenu/NavMenu';
import { NavMenuItem } from '@src/components/NavMenu/NavMenuItem';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { Spinner } from '@src/components/Spinner/Spinner';
import { EventError } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { MembershipInfoCard } from '@src/pages/ChangeMembership/components/MembershipInfoCard';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import {
    errorSelector,
    membershipSelector,
    renewSelector
} from '@src/pages/ChangeMembership/machines/changeMembershipSelectors';
import { useMember } from '@src/services/member/MemberProvider';
import { isNewPremium } from '@src/services/membership.service';
import { getRenewalStartDate } from '@src/utils/helpers/memberHelpers';
import { captureException } from '@src/utils/helpers/newRelic';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { getMembershipUspByCountry } from '@src/utils/helpers/uspHelpers';
import { scrollToAnchor } from '@src/utils/helpers/window';
import {
    ChangeMembershipProps,
    useChangeMembership
} from '@src/utils/hooks/api/useChangeMembership';
import { useSelector } from '@xstate/react';
import { DateTime } from 'luxon';

import { FormattedUSP } from './FormattedUSP';

export const SelectMembership = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const navigate = useNavigate();

    const { state: member } = useMember();
    const {
        membership_type_g: currentMembership,
        mailing_country: country,
        payment_plan: { minimal_contract_period: contractPeriod }
    } = member;

    /**
     * XState events
     */
    const { changeService } = useContext(ChangeMembershipContext);
    const selectedMembership = useSelector(changeService, membershipSelector);
    const stateError = useSelector(changeService, errorSelector);
    const renew = useSelector(changeService, renewSelector);

    const { send } = changeService;

    const [paymentInterval, setPaymentInterval] = useState(PaymentInterval.FourWeeks);
    const [noMembershipsAvailable, setNoMembershipsAvailable] = useState(false);

    const { membershipOptions, isLoading, isError } = useChangeMembership({
        id: member.id_g,
        membership: currentMembership,
        interval: paymentInterval,
        contractPeriod,
        renew
    });

    useEffect(() => {
        if (isError) {
            captureException('failed to fetch membership options', {
                id: member.id_g,
                membership: currentMembership,
                interval: paymentInterval,
                contractPeriod,
                renew: String(renew),
                peopleId: member.people_id_g
            });
        }
    }, [isError]);

    useEffect(() => {
        if (!membershipOptions) return;

        if (membershipOptions?.length === 0) {
            captureException('no memberships available', {
                id: member.id_g,
                membership: currentMembership,
                interval: paymentInterval,
                contractPeriod,
                renew: String(renew)
            });
            send({ type: 'SET.ERROR', value: EventError.NO_MEMBERSHIPS_AVAILABLE });
            setNoMembershipsAvailable(true);
        }
    }, [membershipOptions]);

    useEffect(() => {
        if (stateError === EventError.NO_MEMBERSHIP_SELECTED) {
            scrollToAnchor('#error');
        }
    }, [stateError]);

    /**
     * Set the current data membership when we are renewing the contract
     */
    useEffect(() => {
        if (!renew) return;

        const { payment_plan, membership_type_g: membershipType, membership } = member;
        const { payment_plan_name_g: name } = membership;
        const {
            admin_fee: adminFee,
            term_fee: termFee,
            id,
            cancels_automatically: autoCancel,
            requires_bankaccount: bankAccountRequired,
            interval: paymentInterval,
            minimal_contract_period: minimalContractPeriod
        } = payment_plan;

        const startDate = getRenewalStartDate(member);
        const contractEndDate = DateTime.fromISO(startDate)
            .plus({ weeks: 52 })
            .toISO({ includeOffset: false, suppressMilliseconds: true });

        send({
            type: 'UPDATE.MEMBERSHIP',
            values: {
                adminFee,
                autoCancel,
                bankAccountRequired,
                correctionAmount: 0,
                id,
                possibleIntervals: [paymentInterval as PaymentInterval],
                paymentInterval,
                minimalContractPeriod,
                membershipType,
                name,
                peopleMembership: {
                    startDate,
                    contractEndDate
                },
                prorataAmount: 0,
                typeChange: 'None',
                termFee
            } as ChangeMembershipProps
        });
    }, []);

    const onSelect = (membership: ChangeMembershipProps) => {
        send({ type: 'UPDATE.MEMBERSHIP', values: membership });
    };

    const LinkToExtras = () => {
        const text = t('membership.change.select.extras');
        const linkText = text.split('%%');
        if (linkText.length !== 3) return null;

        return (
            <span>
                {linkText[0]}
                <a href="#add-ons" className="underline" onClick={() => navigate('/membership')}>
                    {linkText[1]}
                </a>
                {linkText[2]}
            </span>
        );
    };

    return (
        <Box variant="grey">
            <Heading size={6}>{t('membership.change.current.title')}</Heading>

            <div className="flex flex-wrap mt-s">
                <Text size={1} className="grow">
                    <strong className="font-heading-treble font-bold capitalize">
                        {currentMembership}
                    </strong>
                </Text>
                <Text size={1} className="text-right">
                    <MemberPriceContract
                        showDiscount={renew ? false : undefined}
                        style="normal"
                        size={1}
                        className="justify-end"
                    />
                </Text>
                <hr className="w-full my-xxs" />
                <Text size={1} className="basis-1/2">
                    {t('435')}
                </Text>
                <Text size={1} className="basis-1/2 text-right">
                    <strong className="font-heading-treble font-bold">
                        {DateTime.fromISO(member.contract_end_date_g)
                            .setLocale(language)
                            .toLocaleString()}
                    </strong>
                </Text>
            </div>

            {!renew && (
                <>
                    <div className="mt-s grid items-start grid-cols-1 xl:grid-cols-2 gap-x-6">
                        {getMembershipUspByCountry(
                            currentMembership,
                            country,
                            isNewPremium(member)
                        ).map((item) => {
                            return <FormattedUSP key={item} id={item} country={country} size={1} />;
                        })}
                    </div>

                    <Heading size={6} className="mt-s">
                        {t('membership.change.title.choose')}
                    </Heading>

                    <NavMenu
                        className="grid grid-cols-[repeat(minmax(1fr,_195px),_2)] mt-l"
                        activeId={paymentInterval === PaymentInterval.FourWeeks ? 0 : 1}
                    >
                        <NavMenuItem
                            className="flex items-center justify-center col-start-1 col-span-1 text-[12px] md:text-[16px]"
                            text={t('1623')}
                            onClick={() => setPaymentInterval(PaymentInterval.FourWeeks)}
                        />
                        <NavMenuItem
                            className="flex items-center justify-center col-start-2 col-span-1 text-[12px] md:text-[16px]"
                            text={t('1624')}
                            onClick={() => setPaymentInterval(PaymentInterval.FiftyTwoWeeks)}
                        />
                    </NavMenu>

                    {stateError === EventError.NO_MEMBERSHIP_SELECTED && (
                        <Text id="error" size={1} className="text-red text-center mt-s">
                            {t('membership.change.select.new')} <LinkToExtras />
                        </Text>
                    )}

                    {isLoading ? (
                        <div className="flex justify-center py-l">
                            <Spinner size="l" />
                        </div>
                    ) : (
                        <div className="mt-l grid items-start grid-cols-1 xl:grid-cols-2 gap-6">
                            {membershipOptions?.map((option) => (
                                <MembershipInfoCard
                                    key={option.id}
                                    membership={option.membershipType}
                                    price={option.termFee}
                                    pricePeriod={
                                        paymentInterval === 'fourWeeks' ? t('per4Weeks') : t('370')
                                    }
                                    highlight={
                                        option.membershipType === selectedMembership?.membershipType
                                    }
                                    active={
                                        option.membershipType === selectedMembership?.membershipType
                                    }
                                    onSelect={() => onSelect(option)}
                                />
                            ))}
                        </div>
                    )}

                    {noMembershipsAvailable && (
                        <Text size={2} className="text-orange mt-xs">
                            {t('1527')}
                        </Text>
                    )}
                </>
            )}
        </Box>
    );
};
