import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@basic-fit/design-system';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { PageHeading } from '@src/pages/ChangeMembership/components/PageHeading';
import { SelectMembership } from '@src/pages/ChangeMembership/components/SelectMembership';
import { useMember } from '@src/services/member/MemberProvider';
import { collectFunnelData } from '@src/utils/helpers/newRelic';
import { useSelector } from '@xstate/react';

import { EventError } from '../ChangeMembershipFlow.types';
import { AreYouSureModal } from '../components/AreYouSureModal.modal';
import { MyChanges } from '../components/MyChanges';
import { ChangeMembershipContext } from '../machines/ChangeMembershipProvider';
import {
    errorSelector,
    membershipSelector,
    renewSelector
} from '../machines/changeMembershipSelectors';
import { isDowngradePath } from '../utils';

export const ChooseMembership = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const { state: member } = useMember();

    const {
        changeService,
        changeService: { send }
    } = useContext(ChangeMembershipContext);
    const selectedMembership = useSelector(changeService, membershipSelector);
    const stateError = useSelector(changeService, errorSelector);
    const renew = useSelector(changeService, renewSelector);

    useEffect(() => {
        const message = renew ? 'prepaid renewal started' : 'change membership started';
        collectFunnelData({
            event: 'navigate',
            message,
            component: 'ChooseMembership'
        });
    }, []);

    const handleContinue = () => {
        if (!selectedMembership) {
            return send({ type: 'SET.ERROR', value: EventError.NO_MEMBERSHIP_SELECTED });
        }

        const isDowngrading = isDowngradePath(
            member.membership_type_g,
            selectedMembership.membershipType
        );
        if (isDowngrading) return setShowModal(true);

        send('NEXT_STEP');
    };

    return (
        <>
            <PageHeading index={1}>
                {t(renew ? 'renewal.extend.title' : 'choose.your.membership')}
            </PageHeading>

            <Wrapper>
                {renew && (
                    <Text size={1} className="mb-m">
                        {t('renewal.extend.body')}
                    </Text>
                )}
            </Wrapper>

            <SelectMembership />

            <MyChanges className={`my-l`} />
            <div className="px-xs pb-l m-auto max-w-[345px]">
                <Button
                    type="submit"
                    disabled={stateError === EventError.NO_MEMBERSHIPS_AVAILABLE}
                    onClick={handleContinue}
                    variant="primary"
                    className="w-full"
                >
                    {t('128')}
                </Button>
                <Button
                    variant="primary"
                    theme="light"
                    className="w-full mt-xs"
                    onClick={() => navigate('/membership')}
                >
                    {t('162')}
                </Button>
            </div>

            {showModal && (
                <AreYouSureModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onContinue={() => send('NEXT_STEP')}
                    currentMembership={member.membership_type_g}
                />
            )}
        </>
    );
};
